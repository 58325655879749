




import OT from "@opentok/client";
export default {
  name: "publisher",
  data() {
    return {
      publisher: null,
    };
  },
  props: {
    cameraOn:{},
    microphoneOn:{},
    session: {
      type: OT.Session,
      required: false
    },
    opts: {
      type: Object,
      required: false
    },
    profileSettings: {
      required: false
    },
    chatIsVisibleAndUnmutedData:{
      required:false
    }
  },
  methods: {
    getPublisher(){
      return this.publisher;
    },
    switchCam() {
      this.publisher
        .cycleVideo()
        .then(this.$parent.$parent.consoleLog)
        .catch(this.$parent.$parent.logErrors);
    },
    getVideoSource(){
      return this.publisher.getVideoSource()
    }
  },
  mounted: function() {
    this.publisher = OT.initPublisher(this.$el, this.opts, err => {
      if (err) {
        this.$emit("error", err);
      } else {
        this.$emit("publisherCompleted");
      }
    });
    var vue_el = this;
    this.publisher.on({
      accessDenied: function accessDeniedHandler() {
        vue_el.$parent.accessDenied = true;
        // The user has denied access to the camera and mic.
      },
      accessAllowed: function() {
        vue_el.$parent.accessDenied = false;
        // The user has granted access to the camera and mic.
      }
    });
    this.publisher.on({
      accessDialogOpened: function() {
        vue_el.$parent.pleaseGrantAccess = true;
        // The Allow/Deny dialog box is opened.
      },
      accessDialogClosed: function() {
        vue_el.$parent.pleaseGrantAccess = false;
        // The Allow/Deny dialog box is closed.
      }
    });

    this.$emit("publisherCreated", this.publisher);
    const publish = async () => {
        await this.session.unpublish(this.publisher)
        this.session.publish(this.publisher, err => {
          if (err) {
            this.$emit("error", err);
          } else {
            this.$emit("publisherConnected", this.publisher);
          }
        });
    };
    if (this.session && this.session.isConnected()) {
      publish();
    }
    if (this.session) {
      this.session.on("sessionConnected", publish);
    }
  },
  watch:{
    microphoneOn() {
      this.publisher.publishAudio(this.microphoneOn);
    },
    cameraOn() {
      this.publisher.publishVideo(this.cameraOn);
    },
    chatIsVisibleAndUnmutedData: function(){
      if(this.chatIsVisibleAndUnmutedData === false){
        this.publisher.publishVideo(false);
        this.session.unpublish(this.publisher);
        this.publisher.destroy();
      }
    }
  },
  beforeDestroy() {
    this.$parent.$parent.consoleLog('#### beforeDestroy', this.publisher)
    this.publisher.publishVideo(false);
    this.session.unpublish(this.publisher);
    this.publisher.destroy();
  },
  destroyed(){
    this.$parent.$parent.consoleLog('#### destroyed', this.publisher)
    if(this.publisher){
      this.publisher.publishVideo(false);
      this.session.unpublish(this.publisher);
      this.publisher.destroy();
    }
  }
};
