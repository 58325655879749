
import Vue from "vue";
import VueRouter from "vue-router";
import VvenueChat from "@/vvenue-chat.vue";

Vue.use(VueRouter);

const Foo = { template: "<div>foo</div>" };
const Bar = { template: "<div>bar</div>" };
const routes = [
  { path: "/foo", component: Foo },
  { path: "/bar", component: Bar }
];
const router = new VueRouter({
  routes // short for `routes: routes`
});

export default Vue.extend({
  router,
  name: "ServeDev",
  components: {
    VvenueChat
  },
  data() {
    return {
      isAnonymVenue: true,
      hasOnlyEmbedded: true,
      roomsByUrl: {
        "/foo": ["Здравствуйте /foo", "test /foo channel 22"],
        "/bar": ["Здравствуйте /bar", "test /bar channel 2"]
      },
      dynamicChannels: [],
      fullScreen: false,
      mainWindowExpandedProp: true,
      openChatByUuidProp: "",
      chatEnabled: true,
      dynamicChannelsString: "",
      chatIsVisibleAndUnmutedProp: true,
      onMessageOpenChatProp: true,
      showActiveUserListProp: true,
      showHideAndMuteChatButtonProp: true,

      optionsShowChangingAliasName: true,
      optionsShowChangingStatus: true,
      currentStatusProp: "Available",
      profilePic:
        "'https://www.berlin.de/binaries/asset/image_assets/2911365/source/1359019870/624x468/'",
      embedded_channel_name: "embedded-channel-name2",
      embedEnabled: true,
      trans: {
        main: {
          chats: 'Chats 2',
          guest: 'Gast',
          settings: {
            Available: 'Available not yet',
            Idle: 'Idle',
            Busy: 'Busy',
            Offline: 'Offline',
            mute_all_sounds: 'Mute all notifications'
          },
          recent_chats: 'RECENT CHATS',
          group_chats: 'GROUP CHATS',
          active_users: 'ACTIVE USERS',
          new: 'new',
          chat_is_offline: 'Chat is offline',
          search: 'Search'
        },
        chat: {
          group_chatroom: 'Group Chatroom',
          private_group_chat: 'Private Group Chat',
          private_chat: 'Private Chat',
          video_chat_invitation: 'Click here to join my video chat!',
          group_channel: {
            channel_name: 'Channel Name',
            enter_a_channel_name: 'Enter a channel name',
            channel_users: 'Channel Users',
            selected_users: 'Selected Users',
            available_users: 'Available Users',
            search_user: 'Search User',
            update: 'Update',
            create: 'Create',
            group_channel: 'Group Channel'
          },
          write_message: 'Write Message',
          emoji_search: 'Search..',
          file_upload: {
            help_text: 'Choose file or drag & drop here',
            max_size_error: 'Files should not exceed 5MB in size',
            not_allowed_type_error: 'Invalid file type.',
            num_files_attached: ' file(s) attached'
          }
        },
        video_chat: {
          currently_no_other_users: 'There are currently no other users.'
        },
        write_message: 'Write Message',
        errors: {
          the_file_upload_failed: 'We are sorry, the upload failed.',
          the_requested_file_is_not_available:
            'The requested file is not available anymore.',
          please_enter_a_name: 'Please enter a name!',
          //could happen if old chat is in messages.
          video_chat_is_not_enabled: 'Video Chat not enabled',
          please_select_at_least_two_users: 'Please select at least two users!',
          please_enter_a_channel_name: 'Please enter a channel name!',
          something_went_wrong_with_the_videochat:
            'We are sorry. Something went wrong with the video chat.',
          //video publisher new 2020-02-11
          unfortunately_you_cannot_publish: 'Unfortunately you cannot publish.',
          video_microphone_access_denied:
            'You have denied access to your microphone/camera. Check your Browser- or Operating System Settings.',
          please_grant_access_to_video_microphone:
            'Please grant access to your microphone/camera. A dialog should be opened right now.'
        }
      },
      transPassObject: {},
      // transDE: {}
      // trans: vue_el.transDE,
      lang: 'DE',

      otCreateSession: async function() {
        try {
          let response = await fetch(
            // this.otSessionUrl
            "https://chat.w3-development.de/vonage_endpoint/getSession.php"
          );
          let response_json = await response.json();
          if (response_json.error) {
            throw new Error(response_json.error);
          }
          return response_json.session;
        } catch (error) {
          console.error("Error at createSession");
          return false;
        }
      },
      otGetToken: async function(session) {
        try {
          let response = await fetch(
            // this.otTokenUrl + "?sessionId=" + session
            "https://chat.w3-development.de/vonage_endpoint/getToken.php?sessionId=" +
              session
          );
          let response_json = await response.json();
          if (response_json.error) {
            throw new Error(response_json.error);
          }
          return response_json.token;
        } catch (error) {
          console.error("Error at getToken");
          return false;
        }
      },
      predef_uuid: "",
      predef_uname: ""
    };
  },
  methods: {
    consoleUuidClicked(event) {
      console.log("uuid-clicked", event);
    },
    updateDynamicChats() {
      console.log(`dynamicChannelsString`, this.dynamicChannelsString);
      this.dynamicChannels = this.dynamicChannelsString.split(",");
    },
    errorLogger(message = null, stack = null, error = null, error_json = null) {
      console.log("errorLogger message", message);
      console.log("errorLogger stack", stack);
      console.error(error);
      console.log("error_json", error_json);
    },
    changeLanguage(){
      let objectRecursive = function(local, lang='DE') {
      for (let key of Object.keys(local)) {
        if(typeof local[key] === "string"){
          local[key] = local[key]+" "+lang;
        } else if(typeof local[key] === "object"){
          local[key] = objectRecursive(local[key], lang)
        }
      }
      return local;
    };
    try {
      if(this.lang == 'DE'){
        this.transPassObject = objectRecursive(JSON.parse(JSON.stringify(this.trans)), 'EN');
        this.lang = 'EN'
      } else {
        this.transPassObject = objectRecursive(JSON.parse(JSON.stringify(this.trans)), 'DE');
        this.lang = 'DE'
      }
    } catch (error) {
      // this.transLocal = this.trans?this.trans:this.transLocal;
      console.error('changeLanguage error',error)
      // this.logErrors(error);
    }
    }
  },
  watch: {
    $route(to) {
      if (to.path) {
        this.dynamicChannels = this.roomsByUrl[to.path];
        //set the channels new for animation
      }
    },
    chatIsVisibleAndUnmutedProp() {
      console.log(
        "chatIsVisibleAndUnmutedProp",
        this.chatIsVisibleAndUnmutedProp
      );
    }
  },
  mounted() {
    


    window.addEventListener("keydown", onKeyDown, false);
    window.addEventListener("keyup", onKeyDown, false);
    window.addEventListener("keypress", onKeyDown, false);

    function onKeyDown(event) {
      var keyCode = event.keyCode;
      switch (keyCode) {
        case 68: //d
          move(1, 0);
          break;
        case 83: //s
          move(0, 1);
          break;
        case 65: //a
          move(-1, 0);
          break;
        case 87: //w
          move(0, -1);
          break;
      }
    }
    function move(x, y) {
      console.log("move", x, y);
      var offsets = document
        .getElementById("center-point")
        .getBoundingClientRect();
      document.getElementById("center-point").style.left =
        offsets.left + x + "px";
      document.getElementById("center-point").style.top =
        offsets.top + y + "px";
    }
  }
});
