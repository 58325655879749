







































































































import { Channel } from "../types.vue";
import {
  SvgComponent,
  CloseButton,
  AddUserButton,
  EditButton,
  SearchButton
} from "./svgs.vue";
import { nanoid } from "nanoid";

export default {
  name: "GroupSettings", // vue component name
  components: {
    SvgComponent,
    CloseButton,
    AddUserButton,
    EditButton,
    SearchButton
  },
  data: () => {
    return {
      showModal: true,
      availableChannels: [],
      selectedChannels: [],
      alter: true,
      display_name: "",
      isLoading: false,
      searchUser: ""
    };
  },
  props: {
    myUuid:{
      type:String,
      required: true
    },
    restricted: {
      type: Boolean,
      required: true
    },
    currentGroupChannel: {
      type: Object,
      required: true
    },
    trans: {
      type: Object,
      required: true
    }
  },
  async mounted() {
    // this.groupChannel = this.currentGroupChannel;
    //group channel exists already
    if (
      this.currentGroupChannel.subscription_name &&
      this.currentGroupChannel.type == "group"
    ) {
      this.display_name = this.currentGroupChannel.display_name;
      if (!this.currentGroupChannel.group_participants) {
        this.currentGroupChannel.group_participants = [];
      }
      this.setChannels(false);
    }
    //new group channel from private channel
    else if (
      this.currentGroupChannel.subscription_name &&
      this.currentGroupChannel.type == "private"
    ) {
      this.setChannels(true);
    }
  },
  methods: {
    setChannels(newChannel:boolean = true){
      this.alter = !newChannel;
      this.selectedChannels = [];
      this.availableChannels = [];
      this.availableChannels = Object.values(this.$parent.channels)
      .filter((chan: Channel) => chan.type == 'private' && chan.uuid)
      .map((chan:Channel) => chan.uuid)
      .filter(
        (uuid:string) =>
          this.$parent.usersData[uuid] &&
          this.$parent.usersData[uuid].name &&
          //old channel
         (newChannel || !this.currentGroupChannel.group_participants.includes(uuid)) &&
         this.$parent.usersData[uuid].custom?.status != "Offline"
      )
      .concat(
        this.$parent.activeUsersUUIDs
          .filter(uuid => 
          !this.$parent.actualChannelUuids.includes(uuid) &&
          (newChannel || !this.currentGroupChannel.group_participants.includes(uuid)) &&
          this.$parent.usersData[uuid] &&
          this.$parent.usersData[uuid].name &&
          this.$parent.usersData[uuid]?.custom?.status != "Offline" )
      );

      if(!newChannel){
        this.selectedChannels = this.currentGroupChannel.group_participants.slice();
      }

      if(this.restricted){
        this.selectedChannels.splice(this.selectedChannels.indexOf(this.$parent.uuid), 1);
        this.selectedChannels.push(this.currentGroupChannel.group_owner)
      }
    },
    selectChannel(uuid:string, select:boolean) {
      let moveChannel = null;
      if (select) {
        let index = this.availableChannels.indexOf(uuid)
        if(index > -1){
          moveChannel = this.availableChannels.splice(index, 1)[0];
          this.selectedChannels.push(moveChannel);
        }
      } else {
        let index = this.selectedChannels.indexOf(uuid)
        moveChannel = this.selectedChannels.splice(index, 1)[0];
        this.availableChannels.push(moveChannel);
      }
    },
    async createGroup() {
      if (this.selectedChannels.length < 2) {
        alert(this.trans.errors.please_select_at_least_two_users);
        return;
      }
      if (!this.display_name || this.display_name == "") {
        alert(this.trans.errors.please_enter_a_channel_name);
        return;
      }
      let loader = this.$parent.loadspinner("#header_" + this.currentGroupChannel.container_div_id);

      this.display_name = this.display_name.replace(/\r?\n|\r/g, "");
      this.display_name = this.display_name.replace(/\&nbsp;/g, "");
      this.display_name = this.display_name.trim();

      if (!this.alter) {
        let channelToInsert: Channel = {
          //### ChatWindow ###
          isOpen: false,
          isExpanded: false,
          currentMessage: "",
          container_div_id: (Math.random() * 1000000).toFixed(0),

          //### ChannelToSubscribe ###
          display_name: this.display_name,
          subscription_name: "group_" + nanoid(),
          avatarBackground: this.$parent.secondaryColor,
          avatarBackgroundOpacity: this.$parent.hslColorFromArbitraryString(
            this.$parent.secondaryColor,
            50,
            75
          ),
          // avatarLetters: this.$parent.$options.filters.getAvatarLetters(
          //   this.display_name
          // ),
          hasMembership: true,
          type: "group", //'public', 'private', 'group'
          group_owner: this.$parent.uuid,
          group_participants: this.selectedChannels
        };
        this.$parent.pns.updateChannelObject(channelToInsert)

        let now = Date.now();

        await Promise.all([
          this.$parent.pns.pubnub_setChannelMetaData(channelToInsert.subscription_name),
          this.$parent.pns.pubnub_setChannelMembership(
          channelToInsert,
          `${now}9999`,
          true),
        ]);


        this.$parent.pns.pubnub_subscribeToChannels(
          [channelToInsert.subscription_name],
          channelToInsert.group_participants.concat([channelToInsert.group_owner])
        ).then(() => this.$parent.openChat(channelToInsert.subscription_name))
      } else {
        //update group chat
        let newGroupParticipants = this.selectedChannels;
        let groupParticipantsToRemove = this.currentGroupChannel.group_participants.filter(
          el => !newGroupParticipants.includes(el)
        );

        this.currentGroupChannel.display_name = this.display_name;
        this.currentGroupChannel.group_participants = newGroupParticipants;
        let now = Date.now();

        Promise.all([
          this.$parent.pns.pubnub_setChannelMetaData(this.currentGroupChannel.subscription_name),
          this.$parent.pns.pubnub_setChannelMembership(
            this.currentGroupChannel,
            `${now}9999`,
            true,
            groupParticipantsToRemove
          ),
        ])

        let promises = [
          this.$parent.pns.pubnub_subscribeToChannels(
            [this.currentGroupChannel.subscription_name],
            this.currentGroupChannel.group_participants.concat([this.currentGroupChannel.group_owner])
          ),
        ]
        if(groupParticipantsToRemove && groupParticipantsToRemove.length){
          promises.push(this.$parent.pns.pubnub_unsubscribeFromChannels([this.currentGroupChannel.subscription_name], [groupParticipantsToRemove]))
        }
        Promise.all(promises)
      }
      this.$emit("close");
      loader.hide();
    },
    async leaveChannel(){
      if(confirm(this.trans.chat.group_channel.are_you_sure_leave)){
        let loader = this.$parent.loadspinner("#header_" + this.currentGroupChannel.container_div_id);
        while(this.intervalIsUpdatingChats){
          await new Promise(res => setTimeout(() => {res()}, 300));
        }
        try {
          this.intervalIsUpdatingChats = true;
          await this.$parent.pns.deleteChannel(this.currentGroupChannel, this.$parent.uuid);
          await this.$parent.pns.removeDeletedChannels()
          this.$parent.forceRerenderComputedProps++
          loader.hide();
          this.intervalIsUpdatingChats = false;
        } catch (error) {
          this.intervalIsUpdatingChats = false;
          this.$parent.logErrors(error)
          loader.hide();
        }
      }
    },
    async deleteGroupChannel(){
      if(confirm(this.trans.chat.group_channel.are_you_sure_delete)){
        let loader = this.$parent.loadspinner("#header_" + this.currentGroupChannel.container_div_id);
        while(this.intervalIsUpdatingChats){
          await new Promise(res => setTimeout(() => {res()}, 300));
        }
        try {
          this.intervalIsUpdatingChats = true;
          await this.$parent.pns.deleteChannel(this.currentGroupChannel);
          await this.$parent.pns.removeDeletedChannels()
          this.$parent.forceRerenderComputedProps++
          loader.hide();
          this.intervalIsUpdatingChats = false;
        } catch (error) {
          this.intervalIsUpdatingChats = false;
          this.$parent.logErrors(error)
          loader.hide();
        }
      }
    },
  },
  computed: {
    availableChannelsSearched() {
      if (!this.searchUser || this.searchUser == ""){
        return this.availableChannels;
      }
      return this.availableChannels.filter((uuid: string) =>
        // el.display_name?.toLowerCase().includes(this.searchUser.toLowerCase())
        this.$parent.usersData[uuid].name.toLowerCase().includes(this.searchUser.toLowerCase())
      );
    }
  },
  filters: {
    cutString(value) {
      if (value && value.length > 15) {
        return value.substring(0, 15) + "...";
      }
      return value;
    }
  }
};
