




import OT from "@opentok/client";
export default {
  name: "subscriber",
  props: {
    stream: {
      type: OT.Stream,
      required: true
    },
    session: {
      type: OT.Session,
      required: true
    },
    opts: {
      type: Object,
      required: false
    },
    chatIsVisibleAndUnmutedData:{
      required:false
    }
  },
  data() {
    return {
      subscriber: null
    };
  },
  methods: {},
  mounted: function() {
    this.subscriber = this.session.subscribe(
      this.stream,
      this.$el,
      this.opts,
      err => {
        if (err) {
          this.$emit("error", err);
        } else {
          this.$emit("subscriberConnected", this.subscriber);
        }
      }
    );
    this.$emit("subscriberCreated", this.subscriber);
  },
  watch:{
    chatIsVisibleAndUnmutedData: function(){
      if(this.chatIsVisibleAndUnmutedData === false){
        this.session.unsubscribe(this.subscriber);
        this.subscriber.destroy();
      }
    }
  },
  beforeDestroy: function() {
    this.session.unsubscribe(this.subscriber);
    this.subscriber.destroy();
  },
};
