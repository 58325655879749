














































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { nanoid } from "nanoid";
import { Channel, ChatData } from "./types.vue";
import GroupSettings from "./components/group-settings.vue";
import InfiniteLoading from "vue-infinite-loading";

import Emojis, { IEmoji } from "./components/emoji.vue";

import VueFileAgent from "vue-file-agent";
import "vue-file-agent/dist/vue-file-agent.css";

Vue.use(VueFileAgent);

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading);

import {
  SvgComponent,
  SendButton,
  SmileyButton,
  AttachButton,
  SearchButton,
  CloseButton,
  AddUserButton,
  MuteButton,
  SpeakerButton,
  MinimizeButton,
  AvatarButton,
  EditButton,
  NotificationButton,
  SettingsButton,
  ChevronRight,
  MessagesButton,
  FullScreenButton,
  VideoButton
} from "./components/svgs.vue";

import VideoChat from "./components/video-chat.vue";

import { PNService } from "./pubnub_functions.vue";
import functions from "./functions.vue";
// import PubnubFunctions from "./pubnub_functions.vue";
// import PNService from './pubnub_functions.vue'
import Pubnub from "pubnub";
// https://github.com/ezolenko/rollup-plugin-typescript2/issues/172#issuecomment-530346887
// import functions from "./functions.ts";
// import pubnub_functions from "./pubnub_functions.ts";

export default Vue.extend({
  name: "VvenueChat", // vue component name
  mixins: [functions],
  components: {
    SvgComponent,
    GroupSettings,
    SendButton,
    SmileyButton,
    AttachButton,
    SearchButton,
    CloseButton,
    AddUserButton,
    MuteButton,
    SpeakerButton,
    InfiniteLoading,
    MinimizeButton,
    AvatarButton,
    EditButton,
    NotificationButton,
    SettingsButton,
    ChevronRight,
    MessagesButton,
    FullScreenButton,
    VideoButton,
    Emojis,
    VideoChat
  },
  data(): ChatData {
    return {
      chatIsVisibleAndUnmutedData: true,
      subscribePrivateChannelsInterval_queue: [],
      pns: null,
      debug: {
        deleteAllMemberships: false,
        openVideoAtStartUp: false,
        overwriteSessionForVideo: false
      },
      channels: {},
      messages: {},
      usersData: {},
      activeUsersUUIDs: [],
      pubnub: null,
      pubnubListener: null,
      baseChannel: "test_base",
      setActiveUsersUUIDsArrayInterval_id: null,
      mainWindowSearch: "",
      channelMessageCounts: {},
      messagesLoading: false,
      mainWindowExpanded: true,
      settingsOpen: false,
      statusColors: {
        Available: "#0ACEE7",
        Idle: "#E1921C",
        Busy: "#BB2222",
        Offline: "#0C1D39"
      },
      profileSettings: {
        name: "Guest",
        status: "Available", //Idle, Busy, Offline
        muteAllSounds: true,
        uuid: "",
        profilePic: null
      },
      sizes: {
        amountChatWindowsPossible: 2,
        windowWidth: 0,
        windowHeight: 0
      },
      openChatCount: 1,
      fullScreen: false,
      respMainWindowOpen: true,
      mobileButtonChecked: false,
      chatWindowScrolling: false,
      showVideoChat: false,
      // chatRoom: null,
      chatIsOnline: true,
      uuid: null,
      videoChatButtonEnabled: true,
      currentVideoChat: null,
      forceRerenderComputedProps: 0,
      dataFilesEnabled: true,
      lockSend: false,
      lastIncomingChannels: [],
      mobileMode: false,
      intervalIsUpdatingChats: false,
      actualChannelUuids: [],
      isEmbed: false,
      transLocal: {
        main: {
          chats: "Chats",
          guest: "Gast",
          settings: {
            Available: "Available",
            Idle: "Idle",
            Busy: "Busy",
            Offline: "Offline",
            mute_all_sounds: "Mute all notifications"
          },
          recent_chats: "RECENT CHATS",
          group_chats: "GROUP CHATS",
          active_users: "ACTIVE USERS",
          new: "new",
          chat_is_offline: "Chat is offline",
          search: "Search",
          loading: "name loading..."
        },
        chat: {
          group_chatroom: "Group Chatroom",
          private_group_chat: "Private Group Chat",
          private_chat: "Private Chat",
          video_chat_invitation: "Click here to join my video chat!",
          group_channel: {
            channel_name: "Channel Name",
            enter_a_channel_name: "Enter a channel name",
            channel_users: "Channel Users",
            selected_users: "Selected Users",
            available_users: "Available Users",
            search_user: "Search User",
            update: "Update",
            create: "Create",
            group_channel: "Group Channel",
            delete: "Delete",
            are_you_sure_delete: "Are you sure to delete the Channel?",
            leave_channel: "Leave Channel",
            are_you_sure_leave: "Are you sure to leave the channel?"
          },
          write_message: "Write Message",
          emoji_search: "Search..",
          file_upload: {
            help_text: "Choose file or drag & drop here",
            max_size_error: "Files should not exceed 5MB in size",
            not_allowed_type_error: "Invalid file type.",
            num_files_attached: " file(s) attached"
          }
        },
        video_chat: {
          currently_no_other_users: "There are currently no other users.",
          // new 2021-11-11 start
          hardware_selection: "Hardware selection",
          video_input: "Videoinput",
          audio_input: "Audioinput"
          // new 2021-11-11 end
        },
        write_message: "Write Message",
        errors: {
          the_file_upload_failed: "We are sorry, the upload failed.",
          the_requested_file_is_not_available:
            "The requested file is not available anymore.",
          please_enter_a_name: "Please enter a name!",
          //could happen if old chat is in messages.
          video_chat_is_not_enabled: "Video Chat not enabled",
          please_select_at_least_two_users: "Please select at least two users!",
          please_enter_a_channel_name: "Please enter a channel name!",
          something_went_wrong_with_the_videochat:
            "We are sorry. Something went wrong with the video chat.",
          //video publisher new 2020-02-11
          unfortunately_you_cannot_publish: "Unfortunately you cannot publish.",
          video_microphone_access_denied:
            "You have denied access to your microphone/camera. Check your Browser- or Operating System Settings.",
          please_grant_access_to_video_microphone:
            "Please grant access to your microphone/camera. A dialog should be opened right now.",
          // new 2021-11-11 start
          your_hardware_is_occupied_by_another_program:
            "The selected voice or video devices are unavailable. Verify that the chosen devices are not in use by another application."
          // new 2021-11-11 end
        }
      }
      // receiveMessagesLock: true,
    };
  },

  props: {
    publishKey: {
      type: String,
      required: true
    },
    subscribeKey: {
      type: String,
      required: true
    },
    //should be a full hex string. no shortcodes.
    primaryColor: {
      type: String,
      default: "#22AABB"
    },
    //should be a full hex string. no shortcodes.
    secondaryColor: {
      type: String,
      default: "#983AD2"
    },
    globalChannels: {
      type: Array,
      default: function() {
        return [];
      }
    },
    // watched array of channel names. These get subscribed as soon as they assigned. They are also persistent for a user if he already interacted with the channel
    dynamicChannels: {
      type: Array,
      default() {
        return [];
      }
    },
    // this must be unique for every session for restoring the pubnub session. Either firebase token or email. If not provided it will be generated for every session and the user is named arbitrarely.
    predefinedUuid: {
      type: String
    },
    // provide an alternative initial alias (in sync if Changes enabled in optionsShowChangingAliasName)
    predefinedAliasName: {
      type: String,
      required: false
    },
    //some functions are interval based. you can manually set this value
    intervalLengthInMs: {
      type: Number,
      default: 30000
    },
    //provide the sound as https:// url - important for cors
    soundUrl: {
      type: String
    },
    mainWindowInitiallyExpanded: {
      default: true
    },
    enableVideoChat: {
      default: false
    },
    //saves the automatically generated uuid in the localStorage - mostly for debug reasons
    saveUserInLocalStorage: {
      default: false
    },
    //logo which is used for globalChannel avatars, either url or base64: so that background-image:url(logoSrc) works
    logoSrc: {
      required: false
    },
    //toggle fullScreen
    isFullScreenProp: {
      required: false,
      type: Boolean
    },
    mainWindowExpandedProp: {
      required: false,
      type: Boolean
    },
    //open chat by (predefined) uuid
    openChatByUuidProp: {
      required: false,
      type: String
    },
    //chat is visible and un-muted
    chatIsVisibleAndUnmutedProp: {
      required: false,
      type: Boolean,
      default() {
        return true;
      }
    },
    //On message open Chat (if chat invisible and muted) if !chatIsVisibleAndUnmutedProp
    onMessageOpenChatProp: {
      required: false,
      type: Boolean,
      default() {
        return true;
      }
    },
    //not sync but watched
    showActiveUserListProp: {
      required: false,
      type: Boolean,
      default() {
        return true;
      }
    },
    //not sync but watched
    //Use this with chatisvisibleandunmuted -> dont use it you dont provide chatIsVisibleAndUnmutedProp from outside
    showHideAndMuteChatButtonProp: {
      required: false,
      type: Boolean,
      default() {
        return false;
      }
    },
    //custom translations
    trans: {
      required: false,
      default() {
        return {};
      }
    },
    //Function to Generate a OpenTok Session
    otCreateSession: {
      required: false
    },
    //Function to Generate a OpenTok Token
    otGetToken: {
      required: false
    },
    // OpenTok ApiKey
    otApiKey: {
      required: false
    },
    // Optional function for logging errors: errorLogger(errorMsg: string, trace:string)
    externalErrorLogger: {
      required: false
    },
    filesEnabled: {
      required: false,
      default: true
    },
    // Enable changing the username for the user (predefinedAliasName is in sync)
    optionsShowChangingAliasName: {
      type: Boolean,
      required: false,
      default: function() {
        return true;
      }
    },
    // Enables the user to change his status
    optionsShowChangingStatus: {
      type: Boolean,
      required: false,
      default: function() {
        return true;
      }
    },
    // current status (in sync if enabled in optionsShowChangingStatus)
    // Available, Idle, Busy, Offline
    currentStatusProp: {
      type: String,
      required: false
    },
    // predefined profile pic // not sync
    profilePic: {
      type: String,
      required: false
    },
    // if defined the chat will open as an embedded chat with the passed string as chatname
    embeddedChat: {
      type: String,
      required: false,
      default: function() {
        return null;
      }
    },
    //padding for embedded mode for preventing overlapping embedded container buttons
    embeddedIconPaddingRight: {
      type: String,
      default: '0px'
    },
    // used for showing settings in embedded mode if isAnonymVenue and hasOnlyEmbedded
    isAnonymVenue: {
      type: Boolean,
      default: false
    },
    hasOnlyEmbedded: {
      type: Boolean,
      default: false
    },
  },

  methods: {
    checkTranslationObjects() {
      let objectRecursive = function(local, external) {
        for (let key of Object.keys(local)) {
          if (
            typeof local[key] === "string" &&
            key in external &&
            typeof external[key] === "string"
          ) {
            local[key] = external[key];
          } else if (
            local[key] &&
            typeof local[key] === "object" &&
            key in external &&
            typeof external[key] === "object"
          ) {
            local[key] = objectRecursive(local[key], external[key]);
          }
        }
        return local;
      };
      try {
        if (typeof this.trans === "object" && this.trans) {
          this.transLocal = objectRecursive(this.transLocal, this.trans);
        }
      } catch (error) {
        // this.transLocal = this.trans?this.trans:this.transLocal;
        this.logErrors(error);
      }
    },
    getProfilePic(uuid: string) {
      // this.consoleLog('uuid', uuid)
      // this.consoleLog('profile', this.usersData[uuid])
      if (!uuid) return null;
      if (this.usersData[uuid]) {
        if (
          this.usersData[uuid]["custom"] &&
          this.usersData[uuid]["custom"]["profilePic"]
        ) {
          // this.consoleLog(`this.usersData[uuid].profilePic`, this.usersData[uuid]['custom']['profilePic']);

          return "url(" + this.usersData[uuid]["custom"]["profilePic"] + ")";
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    uuidClicked(uuid: string) {
      this.consoleLog(`this.usersData[uuid]`, this.usersData[uuid]);
      if (
        this.usersData[uuid] &&
        this.usersData[uuid]["custom"] &&
        this.usersData[uuid]["custom"].uuid
      ) {
        this.$emit("uuid-clicked", this.usersData[uuid]["custom"].uuid);
      }
    },

    loadspinner(id, canCancel = false) {
      let container = null;
      if (typeof id === "string") {
        container = this.$el.querySelector(
          // "#footer_" + channel.container_div_id
          id
        );
      } else if (id) {
        container = id;
      }
      let loader = this.$loading.show({
        container,
        canCancel,
        isFullPage: !container
      });
      setTimeout(() => {
        loader.hide();
      }, 10000);
      return loader;
    },
    async checkAndCloseProfileSettings() {
      if (!this.profileSettings.name || this.profileSettings.name == "") {
        alert(this.transLocal.errors.please_enter_a_name);
        return;
      }
      this.profileSettings.name = this.profileSettings.name.replace(
        /\r?\n|\r/g,
        ""
      );
      this.profileSettings.name = this.profileSettings.name.replace(
        /\&nbsp;/g,
        ""
      );
      this.profileSettings.name = this.profileSettings.name.trim();
      this.$emit("update:predefinedAliasName", this.profileSettings.name);
      this.$emit("update:currentStatusProp", this.profileSettings.status);
      this.pns.pubnub_saveProfileSettings();
      this.settingsOpen = false;
      return;
    },
    async update_previewUrls() {
      for (let key of Object.keys(this.messages)) {
        for (let msg of [...this.messages[key]].reverse()) {
          try {
            if (!msg.preview && msg.message && msg.message.file) {
              let fname = msg.message.file.name;
              if (
                fname.endsWith(".jpg") ||
                fname.endsWith(".jpeg") ||
                fname.endsWith(".png") ||
                fname.endsWith(".gif")
              ) {
                let res = await this.pns.pubnub_downloadFile(msg, true);
                msg.preview = res;
              }
            }
          } catch (error) {
            this.logErrors(error);
          }
        }
      }
    },
    setCaretToEnd(target /*: HTMLDivElement*/) {
      if (typeof target === "string") {
        target = this.$el.querySelector("#chat_input_" + target);
      }
      setTimeout(() => {
        const range = document.createRange();
        const sel = window.getSelection();
        range.selectNodeContents(target);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
        target.focus();
        range.detach(); // optimization

        // set scroll to the end if multiline
        target.scrollTop = target.scrollHeight;
      }, 200);
    },
    selectEmoji($event: IEmoji, channel: Channel) {
      channel.currentMessage = channel.currentMessage + $event.data;
      let el = this.$el.querySelector(
        "#chat_input_" + channel.container_div_id
      );

      this.setCaretToEnd(el);
    },
    async infiniteHandler($state, cw) {
      // if (!this.messagesLoading) {
      this.messagesLoading = true;
      try {
        await this.pns.pubnub_fetchMessages(
          [cw.subscription_name],
          this.messages[cw.subscription_name][0].timetoken - 1
        );
        await this.update_previewUrls();
        this.forceRerenderComputedProps++;
        $state.loaded();
        this.messagesLoading = false;
        this.forceRerenderComputedProps++;
      } catch (error) {
        $state.complete();
        this.messagesLoading = false;
        this.forceRerenderComputedProps++;
        // this.logErrors(error);
      }
      // }
    },
    updateSearch(event: any) {
      this.mainWindowSearch = event.target.innerText;
    },

    onBlurChat(event: any, key: string) {
      if (!this.lockSend) {
        this.channels[key].currentMessage = event.target.innerText;
      }
    },
    async downloadFile(message: any) {
      this.pns.pubnub_downloadFile(message);
    },
    async handleFileUpload(key: string) {
      if (
        this.channels[key] &&
        this.channels[key].fileRecords &&
        this.channels[key].fileRecords.length
      ) {
        let spinner = this.loadspinner(
          "#footer_" + this.channels[key].container_div_id
        );
        await this.pns.pubnub_sendFiles(key);
        spinner.hide();
        this.channels[key].fileRecords = [];
        this.channels[key].uploadExpanded = false;
      }
      return;
    },
    async chatPressSend(key: string) {
      if (!this.lockSend) {
        this.lockSend = true;
        await this.handleFileUpload(key);

        if (
          this.channels[key].currentMessage &&
          this.channels[key].currentMessage.replace(
            /[\r\n\x0B\x0C\u0085\u2028\u2029\s]+/g,
            ""
          ).length
        ) {
          await this.pns.pubnub_pushMessage(
            key,
            this.channels[key].currentMessage
          );
          this.channels[key].currentMessage = "";
        }
        this.channels[key].uploadExpanded = false;
        this.channels[key].emojiExpanded = false;
        this.scrollToBottom(
          "#content_" + this.channels[key].container_div_id,
          50,
          true
        );
        this.lockSend = false;
      }
    },
    async chatPressEnter(event: any, key: string) {
      if (!this.lockSend) {
        this.lockSend = true;
        await this.handleFileUpload(key);
        this.channels[key].currentMessage = event.target.innerText;
        if (
          !event ||
          event.shiftKey ||
          !this.channels[key].currentMessage ||
          !this.channels[key].currentMessage.replace(
            /[\r\n\x0B\x0C\u0085\u2028\u2029\s]+/g,
            ""
          ).length
        ) {
          this.lockSend = false;
          return;
        }
        await this.pns.pubnub_pushMessage(
          key,
          this.channels[key].currentMessage
        );
        this.channels[key].currentMessage = "";
        event.target.innerHTML = "";
        this.channels[key].uploadExpanded = false;
        this.channels[key].emojiExpanded = false;
        this.scrollToBottom(
          "#content_" + this.channels[key].container_div_id,
          50,
          true
        );
        this.lockSend = false;
      }
    },
    async openVideoChat(chatWindow: Channel, fromMessage: boolean = false) {
      if (!this.lockSend) {
        this.lockSend = true;
        try {
          if (
            typeof this.otCreateSession === "undefined" ||
            typeof this.otGetToken === "undefined" ||
            !this.otApiKey
          ) {
            alert(this.transLocal.errors.video_chat_is_not_enabled);
            this.lockSend = false;
            return;
          }
          if (fromMessage && !chatWindow.otSession) {
            await new Promise(async res => {
              setTimeout(async () => {
                await this.pns.pubnub_getChannelMetaData(chatWindow, true);
                res();
              }, 100);
            });
          }
          if (!chatWindow.otSession || this.debug.overwriteSessionForVideo) {
            chatWindow.otSession = await this.otCreateSession();
            //todo save session in channel object?
          }
          if (!chatWindow.otToken) {
            chatWindow.otToken = await this.otGetToken(chatWindow.otSession);
          }

          this.currentVideoChat = {
            otSession: chatWindow.otSession,
            otToken: chatWindow.otToken,
            otApiKey: this.otApiKey,
            display_name: chatWindow.display_name,
            type: chatWindow.type
          };
          this.showVideoChat = false;
          this.showVideoChat = true;

          // only send invitation if is not already sent one.
          if (
            !fromMessage
            //dont send again if is there
            // &&
            // (!this.messages[chatWindow.subscription_name] ||
            //   this.messages[chatWindow.subscription_name].filter(
            //     msg =>
            //       msg.message &&
            //       msg.message.description &&
            //       msg.message.description == "otChatOpen_____invite"
            //   ).length == 0)
          ) {
            await this.pns.pubnub_setChannelMetaData(
              chatWindow.subscription_name
            );
            let message = "otChatOpen_____invite";
            await this.pns.pubnub_pushMessage(
              chatWindow.subscription_name,
              message
            );
          }
          this.lockSend = false;
        } catch (error) {
          this.lockSend = false;
          this.logErrors(error);
          // alert(this.transLocal.errors.something_went_wrong_with_the_videochat)
        }
      }
    },
    openLastIncoming(
      forceToggle: boolean = false,
      forceOpen: boolean = false,
      forceClose: boolean = false,
      fromUuid: boolean = false
    ) {
      if (!this.lastIncomingChannels.length) return;
      this.openChat(
        this.lastIncomingChannels.shift().subscription_name,
        forceToggle,
        forceOpen,
        forceClose,
        fromUuid
      );
    },
    async openChat(
      key: string,
      forceToggle: boolean = false,
      forceOpen: boolean = false,
      forceClose: boolean = false,
      fromUuid: boolean = false
    ) {
      // this.consoleLog('openChat')
      if (forceClose) {
        this.channels[key].isExpanded = false;
        this.channels[key].isOpen = false;
        this.forceRerenderComputedProps++;
        return;
      }

      if (this.fullScreen && this.mobileMode) {
        this.toggleRespMainWindowOpen();
      }

      if (!(key in this.channels)) {
        let uuid = null;
        if (fromUuid) {
          uuid = key;
          key = await this.digestMessage([this.uuid, key].sort().join("_"));
        }
        key = (await this.pns.setPrivateChannelAndSubscribe(key, false, uuid))
          .subscription_name;
        // this.consoleLog('openChat this.channels[key]', this.channels[key])
      }

      if (
        this.channels[key].type == "group" &&
        !this.channels[key].group_participants
      ) {
        try {
          await this.pns.pubnub_getChannelMembers(this.channels[key]);
        } catch (error) {
          console.error(error);
        }
      }

      //delete from
      if (this.lastIncomingChannels && this.lastIncomingChannels.length) {
        let lIndex = this.lastIncomingChannels.findIndex(
          el => el.subscription_name == key
        );
        if (lIndex > -1) {
          this.lastIncomingChannels.splice(lIndex, 1);
        }
      }

      if (this.lastIncomingChannels.length) {
        let lastIncomingChannelsIndex = this.lastIncomingChannels.findIndex(
          (el: Channel) => {
            return el.subscription_name == key;
          }
        );
        if (lastIncomingChannelsIndex > -1) {
          this.lastIncomingChannels.splice(lastIncomingChannelsIndex, 1);
        }
      }

      // this.consoleLog(`openChat this.channels[key]`, this.channels[key]);

      this.channels[key].new = false;

      let searchOpenChatIndex = this.onlyOpenChannels.indexOf(key);

      if (searchOpenChatIndex < 0 || forceOpen) {
        forceOpen = true;
        let openToggled = false;
        let openStateBefore = this.channels[key].isOpen;
        if (!this.channels[key].expansion_touched || forceToggle) {
          this.channels[key].isOpen = forceOpen || !this.channels[key].isOpen;
          this.channels[key].isExpanded =
            forceOpen || this.channels[key].isOpen;
        }
        openToggled = openStateBefore != this.channels[key].isOpen;

        //because flex-direction end we put the actual open windows at the end of the array, from newest opened window to latest
        if (this.channels[key].isOpen) {
          this.channels[key].openChatCount = this.openChatCount;
          this.openChatCount++;
        }

        if (
          this.channels[key].isOpen &&
          this.channels[key].isExpanded &&
          !(this.mobileMode && !this.mobileButtonChecked)
        ) {
          this.channelMessageCounts[key] = 0;

          //if no messages: fetch some and update picture preview
          if (
            // !this.messages[channel.subscription_name] ||
            // !this.messages[channel.subscription_name].length
            openToggled
          ) {
            this.messages[this.channels[key].subscription_name] = [];
            this.messagesLoading = true;
            await this.pns.pubnub_fetchMessages([
              this.channels[key].subscription_name
            ]);
            await this.update_previewUrls();
            this.messagesLoading = false;
          }

          //set membership only if messages are available to show in recent chats / else the channel will be shown in recent chats without interaction
          if (this.messages[key] && this.messages[key].length) {
            let now = Date.now();
            this.pns.pubnub_setChannelMembership(
              this.channels[key],
              `${now}9999`
            );
            this.channels[key].hasMembership = true;
          }

          this.forceRerenderComputedProps++;

          setTimeout(() => {
            //close all not open channels

            let openChans = this.onlyOpenChannels;
            for (let key of Object.keys(this.channels)) {
              if (!openChans.includes(key)) {
                this.channels[key].isOpen = false;
                this.channels[key].isExpanded = false;
              }
            }

            openChans.forEach(key => {
              this.scrollToBottom(
                "#content_" + this.channels[key].container_div_id,
                50,
                openToggled
              );
            });
            this.scrollToBottom(
              "#content_" + this.channels[key].container_div_id,
              50,
              openToggled
            );
            let focusEl = this.$el.querySelector(
              "#chat_input_" + this.channels[key].container_div_id
            );
            this.setCaretToEnd(focusEl);
            this.forceRerenderComputedProps++;
          }, 300);
        }
      }
      this.forceRerenderComputedProps++;
    },
    handleResize(withEmit: boolean = true) {
      //breakpoint to mobile
      if (
        (window.innerWidth <= 930 && this.sizes.windowWidth > 930) ||
        (window.innerHeight <= 550 && this.sizes.windowHeight > 550)
      ) {
        this.mobileButtonChecked = false;
        //breakpoint to normal
      } else if (
        (window.innerWidth >= 930 && this.sizes.windowWidth < 930) ||
        (window.innerHeight >= 550 && this.sizes.windowHeight < 550)
      ) {
        this.mobileButtonChecked = false;
      }
      this.sizes.windowWidth = window.innerWidth;
      this.sizes.windowHeight = window.innerHeight;

      this.mobileMode =
        this.sizes.windowWidth < 930 || this.sizes.windowHeight < 550;

      this.sizes.amountChatWindowsPossible =
        Math.floor(this.sizes.windowWidth / 360) - 1;
      if (withEmit) {
        this.emitCurrentMainWindow();
      }
      this.forceRerenderComputedProps++;
    },
    hideAndMuteChat() {
      this.chatIsVisibleAndUnmutedData = true;
      this.$emit("update:chatIsVisibleAndUnmutedProp", false);
    },
    emitCurrentMainWindow() {
      if (this.mobileMode) {
        this.$emit(
          "update:mainWindowExpandedProp",
          this.fullScreen && this.respMainWindowOpen && this.mainWindowExpanded
        );
      } else {
        this.$emit("update:mainWindowExpandedProp", this.mainWindowExpanded);
      }
    },
    handleFullScreenWatcher() {
      this.fullScreen = this.isFullScreenProp;
      if (this.fullScreen) {
        this.mainWindowExpanded = true;
      } else {
        if (this.sizes.windowWidth < 930) {
          this.mobileButtonChecked = false;
        }
      }
      this.forceRerenderComputedProps++;
    },
    handleMainWindowExpandedProp() {
      if (this.mobileMode) {
        if (this.mainWindowExpandedProp) {
          this.fullScreen = this.mainWindowExpanded = this.mobileButtonChecked = true;
        }
        this.respMainWindowOpen = this.mainWindowExpandedProp;
      } else {
        if (this.fullScreen) {
          this.respMainWindowOpen = this.mainWindowExpandedProp;
        } else {
          this.mainWindowExpanded = this.mainWindowExpandedProp;
        }
      }
      this.forceRerenderComputedProps++;
    },
    toggleMobileButton(open: boolean) {
      if (typeof open === "undefined") {
        this.mobileButtonChecked = !this.mobileButtonChecked;
      } else {
        this.mobileButtonChecked = open;
      }
      if (this.mobileButtonChecked) {
        this.mainWindowExpanded = true;
        this.respMainWindowOpen = true;
        if (this.allMessageCount && this.lastIncomingChannels.length) {
          this.respMainWindowOpen = false;
          this.openLastIncoming(true, true, false);
        }
      }
      this.fullScreen = this.mobileButtonChecked;
      this.forceRerenderComputedProps++;
      this.emitCurrentMainWindow();
    },
    toggleRespMainWindowOpen() {
      this.respMainWindowOpen = !this.respMainWindowOpen;
      this.forceRerenderComputedProps++;
      this.emitCurrentMainWindow();
    },
    toggleMainWindowExpanded(open: boolean) {
      if (typeof open === "undefined") {
        this.mainWindowExpanded = !this.mainWindowExpanded;
      } else {
        this.mainWindowExpanded = open;
      }
      this.forceRerenderComputedProps++;
      this.emitCurrentMainWindow();
    },
    consoleLog() {
      if (process.env.NODE_ENV != "production" && (this.isEmbed || true)) {
        console.log(...arguments);
      }
    },
    consoleTime(name: string) {
      if (process.env.NODE_ENV != "production" && this.isEmbed) {
        console.time(name);
      }
    },
    consoleTimeEnd(name: string) {
      if (process.env.NODE_ENV != "production" && this.isEmbed) {
        console.timeEnd(name);
      }
    }
  },

  async mounted() {
    if (this.embeddedChat && this.embeddedChat != "") {
      //load embeddedchat
      this.isEmbed = true;
    }
    this.chatIsVisibleAndUnmutedData = this.chatIsVisibleAndUnmutedProp;
    // pns.sendRequest();
    this.consoleLog(`process.env.NODE_ENV`, process.env.NODE_ENV);

    this.consoleLog(`this.openChatByUuidProp`, this.openChatByUuidProp);

    this.pns = new PNService(this);
    this.baseChannel = btoa(this.subscribeKey);

    this.channels = {};
    this.messages = {};

    this.checkTranslationObjects();

    this.dataFilesEnabled = this.filesEnabled;

    try {
      this.primaryColorOpacity = this.hexToRgbA(this.primaryColor);
      this.secondaryColorOpacity = this.hexToRgbA(this.secondaryColor);
    } catch (error) {
      this.primaryColorOpacity = this.primaryColor;
      this.secondaryColorOpacity = this.secondaryColor;
    }

    if (
      typeof this.otCreateSession === "undefined" ||
      typeof this.otGetToken === "undefined" ||
      !this.otApiKey ||
      this.otApiKey == ""
    ) {
      this.videoChatButtonEnabled = false;
    }
    this.handleResize(false);
    if (!this.predefinedUuid || this.predefinedUuid == "") {
      this.uuid = "user_" + nanoid();
    } else {
      this.uuid = this.predefinedUuid.replace(/[^0-9a-zA-Z_]/g, char =>
        char.charCodeAt(0)
      );
    }
    //
    if (
      this.saveUserInLocalStorage &&
      (!this.predefinedUuid || this.predefinedUuid == "")
    ) {
      if (localStorage.vvenue_uuid) {
        this.uuid = localStorage.vvenue_uuid;
      }
      localStorage.vvenue_uuid = this.uuid;
    }

    const originalUUID = this.uuid;
    this.uuid = await this.digestMessage(this.uuid);
    this.consoleLog("mounted this.uuid", this.uuid);

    if (typeof this.isFullScreenProp !== "undefined") {
      this.handleFullScreenWatcher();
    }
    if (typeof this.mainWindowExpandedProp !== "undefined") {
      this.handleMainWindowExpandedProp();
    }

    // this.currentRelativePath = this.route.path
    //if has videochat room in get params:
    // let queryString = window.location.search;
    // let urlParams = new URLSearchParams(queryString);
    // let chat_room = urlParams.get("chat_room");
    // if (chat_room && chat_room.length) {
    //   this.chatRoom = chat_room;
    //   this.showVideoChat = true;
    // }
    this.mainWindowExpanded = this.mainWindowInitiallyExpanded;
    if (this.soundUrl) {
      this.sound = new Audio(this.soundUrl);
    }

    await this.pns.pubnub_getInstance();

    // await this.pubnub.unsubscribeAll()
    this.pns.pubnub_setListener();

    //### get existing settings
    await this.pns.pubnub_setProfileSettings();

    // ### overwrite predefined settings
    if (this.profileSettings.name == "Guest" || this.predefinedAliasName) {
      this.profileSettings.name =
        this.predefinedAliasName ||
        this.transLocal.main.guest +
          " " +
          this.surnames[
            Math.floor(Math.random() * 10000) % this.surnames.length
          ];
    }

    this.profileSettings.uuid = originalUUID;

    if (this.profilePic) {
      this.profileSettings["profilePic"] = this.profilePic;
      this.consoleLog(
        "this.profileSettings.profilePic",
        this.profileSettings.profilePic
      );
    }

    if (this.currentStatusProp) {
      this.profileSettings["status"] = this.currentStatusProp;
    }

    this.pns.pubnub_saveProfileSettings();

    if (!this.isEmbed) {
      // ### subscribe globalChannels channels ###
      let channelsToSubscribe = this.globalChannels.map(display_name => {
        return { display_name, dynamic: false };
      });
      if (this.dynamicChannels && this.dynamicChannels.length) {
        for (let chan of this.dynamicChannels) {
          if (chan.url) {
            channelsToSubscribe.push({
              display_name: chan.channel,
              dynamic: true
            });
          } else if (typeof chan === "string") {
            channelsToSubscribe.push({ display_name: chan, dynamic: true });
          }
        }
      }

      for (let el of channelsToSubscribe) {
        //clean string according to rules here : https://www.pubnub.com/docs/platform/channels/overview
        // let cleanName = el.replace(/[^0-9a-zA-Z_\-\=\@\.\!\$\#\%\&\^\s;]/g, "_");
        //new cleaning because channel names for file uploads seem much stricter than the docs...
        let cleanName = el.display_name.replace(/[^0-9a-zA-Z_]/g, char =>
          char.charCodeAt(0)
        );
        let chatWindow = {
          //### ChatWindow ###
          isOpen: false,
          isExpanded: false,
          currentMessage: "",
          container_div_id: (Math.random() * 1000000).toFixed(0),

          //### ChannelToSubscribe ###
          display_name: el.display_name,
          subscription_name: cleanName,
          avatarBackground: this.primaryColor,
          //make colors for channelnames
          avatarBackgroundOpacity: this.hslColorFromArbitraryString(
            this.primaryColor,
            50,
            75
          ),
          type: "public",
          mute: false,
          emojiExpanded: false,
          uploadExpanded: false,
          fileRecords: [],
          openChatCount: 0,
          dynamic: el.dynamic
          // relativePath: el.url
        } as Channel;
        this.channels[chatWindow.subscription_name] = chatWindow;
      }
      this.consoleLog(`this.uuid`, this.uuid);
      this.consoleLog("this.baseChannel", this.baseChannel);
      // separately subscribe to a baseChannel for events for reducing redundant signalling
      let cleanChannelNames = Object.keys(this.channels);
      this.pns.pubnub_subscribeToChannels(cleanChannelNames);
      this.pns.handleDynamicChannelOnUpdate();
      // ### get active users ###
      // setTimeout(async () => {
      // this.receiveMessagesLock = false;
      this.pns.pubnub_subscribePrivateChannelsInterval();
      // this.pns.pubnub_subscribePrivateChannels().then(() => {
      //   this.pns.pubnub_setUnreadMessagesCount();
      //   this.pns.pubnub_fetchMessages(Object.keys(this.channels));
      // });
      (<Pubnub>this.pubnub).subscribe({
        channelGroups: [this.uuid]
      });
    } else {
      // EMBED
      let embeddedChannelClean = this.embeddedChat.replace(
        /[^0-9a-zA-Z_]/g,
        char => char.charCodeAt(0)
      );
      let chatWindow = {
        //### ChatWindow ###
        isOpen: true,
        isExpanded: true,
        currentMessage: "",
        container_div_id: (Math.random() * 1000000).toFixed(0),

        //### ChannelToSubscribe ###
        display_name: this.embeddedChat,
        subscription_name: embeddedChannelClean,
        avatarBackground: this.primaryColor,
        //make colors for channelnames
        avatarBackgroundOpacity: this.hslColorFromArbitraryString(
          this.primaryColor,
          50,
          75
        ),
        type: "public",
        mute: false,
        emojiExpanded: false,
        uploadExpanded: false,
        fileRecords: [],
        openChatCount: 0,
        dynamic: false
        // relativePath: el.url
      } as Channel;
      this.channels[chatWindow.subscription_name] = chatWindow;
      //subscribe and open embed channel

      (<Pubnub>this.pubnub).subscribe({
        channels: [embeddedChannelClean]
      });
      this.pns.pubnub_fetchMessages([embeddedChannelClean]).then(async () => {
        this.forceRerenderComputedProps++;
        await this.update_previewUrls()
        this.scrollToBottom(
          "#content_" + chatWindow.container_div_id,
          800,
          true
        );
      });
    }

    this.pns.pubnub_subscribePrivateChannelsInterval();
    this.pns.pubnub_subscribePrivateChannels().then(() => {
      if (!this.isEmbed) {
        this.pns.pubnub_setUnreadMessagesCount();
        this.pns.pubnub_fetchMessages(Object.keys(this.channels));
      }
    });

    // BUG: hereNow not working on channels in channelgroups.
    // this.pns.pubnub_subscribeToChannels([this.baseChannel]); // presence needed?

    //check if files available
    this.pubnub
      .listFiles({
        channel: (<Channel[]>Object.values(this.channels))[0].subscription_name,
        limit: 2
      })
      .then(() => {})
      .catch(err => {
        this.logErrors(err);
        try {
          if (err.status.errorData.error.code == 3305) {
            this.dataFilesEnabled = false;
          }
        } catch (error) {}
      });
    //video debug
    // return
    if (this.debug.openVideoAtStartUp) {
      setTimeout(() => {
        let videoChannel = (<Channel[]>Object.values(this.channels)).filter(
          el => el.display_name == "sweet video chat 2"
        );
        this.openVideoChat(videoChannel[0], true);
      }, 5000 + Math.floor(Math.random() * 1000));
    }
    // this.forceRerenderComputedProps++;
    this.forceRerenderComputedProps++;
    // }, 300);
  },
  computed: {
    allMessageCount: function() {
      this.forceRerenderComputedProps;
      return Object.values(this.channelMessageCounts).reduce(
        (prev: number, curr: number) => prev + curr,
        0
      );
    },
    onlyOpenChannels: function() {
      this.forceRerenderComputedProps;
      if (!this.chatIsOnline) return [];
      let result = Object.keys(this.channels)
        .filter(key => this.channels[key].isOpen)
        .sort((a, b) =>
          this.channels[a].openChatCount > this.channels[b].openChatCount
            ? 1
            : this.channels[a].openChatCount < this.channels[b].openChatCount
            ? -1
            : 0
        );
      if (!this.isEmbed) {
        result = result.slice(
          this.fullScreen ? -1 : -this.sizes.amountChatWindowsPossible
        );
      }
      // this.consoleLog(`onlyOpenChannels result`, result);
      // let result = this.channels
      //   .filter((el: Channel) => el.isOpen)
      //   .slice(this.fullScreen ? -1 : -this.sizes.amountChatWindowsPossible);
      if (this.fullScreen && result.length) {
        this.channels[result[0]].isExpanded = true;
      }
      return result;
    },
    cssVars() {
      return {
        "--vvc-primary-color": this.primaryColor,
        "--vvc-secondary-color": this.secondaryColor
      };
    },
    activeUsersUUIDsFiltered: function() {
      if (!this.showActiveUserListProp) return [];
      this.forceRerenderComputedProps;
      this.pns?.setActualChannelUuidsArray();
      let result = this.activeUsersUUIDs
        .filter(uuid => {
          if (
            // this.usersData[uuid] &&
            // this.usersData[uuid].custom &&
            // this.actualChannelUuids.includes(uuid) ||
            this.uuid == uuid ||
            typeof this.usersData[uuid] === "undefined" ||
            this.usersData[uuid]?.custom?.status == "Offline" ||
            !this.usersData[uuid]?.name
          ) {
            return false;
          }
          if (this.mainWindowSearch == "") {
            return true;
          } else {
            if (typeof this.usersData[uuid]?.name !== "undefined") {
              let wordExp = new RegExp(this.mainWindowSearch, "i");
              return wordExp.test(this.usersData[uuid].name);
            }
            return false;
          }
        })
        .map(uuid => {
          return {
            display_name: this.usersData[uuid]?.name,
            profile_picture: this.getProfilePic(uuid),
            uuid: uuid
          };
        })
        .sort((a, b) => {
          return a.display_name.localeCompare(b.display_name);
          // if(!a.display_name && !b.display_name) return 0;
          // if(a.display_name && !b.display_name) return 1;
          // if()
        });
      return result;
    },
    channelsComputed: function() {
      this.forceRerenderComputedProps;

      let channels = [
        //recent active, groups
        [
          ...Object.keys(this.channels).filter(
            key =>
              this.channels[key].hasMembership &&
              this.channels[key].type == "public"
          ),
          ...Object.keys(this.channels).filter(
            key =>
              this.channels[key].hasMembership &&
              ["private", "group"].includes(this.channels[key].type)
          )
        ],
        //public chats, groups
        Object.keys(this.channels).filter(
          key =>
            this.channels[key].type == "public" ||
            this.channels[key].type == "group"
        )
      ];
      // if(this.showActiveUserListProp){
      //   channels.push(//active users
      //   Object.keys(this.channels).filter((key) => {
      //     // if(el.type == 'group') return false
      //     if (this.channels[key].type == "private") {
      //       if (!this.usersData[this.channels[key].uuid]) return false;
      //       if  (!this.activeUsersUUIDs.includes(this.channels[key].uuid)) return false;
      //       if (!this.usersData[this.channels[key].uuid].custom) return true;
      //       if (this.usersData[this.channels[key].uuid].custom.status != "Offline") return true;
      //     }
      //     return false;
      //   }))
      // }
      channels = channels.map(el =>
        el
          .filter(key => this.channels[key].display_name)
          .sort((a, b) => {
            if (
              this.channels[a].type == "public" &&
              this.channels[b].type != "public"
            ) {
              return -1;
            } else if (
              this.channels[a].type != "public" &&
              this.channels[b].type == "public"
            ) {
              return 1;
            } else {
              return this.channels[a].display_name >
                this.channels[b].display_name
                ? 1
                : this.channels[a].display_name < this.channels[b].display_name
                ? -1
                : 0;
            }
          })
          .sort((a, b) => {
            // if(!Object.keys(this.messages).length){
            //   if(!this.channelMessageCounts[this.channels[a].subscription_name] && !this.channelMessageCounts[this.channels[b].subscription_name]) return 0
            //   if(this.channelMessageCounts[this.channels[a].subscription_name] && !this.channelMessageCounts[this.channels[b].subscription_name]) return -1
            //   if(!this.channelMessageCounts[this.channels[a].subscription_name] && this.channelMessageCounts[this.channels[b].subscription_name]) return 1
            // }
            let msg_count_a =
              this.messages[this.channels[a].subscription_name] &&
              this.messages[this.channels[a].subscription_name].length;
            let msg_count_b =
              this.messages[this.channels[b].subscription_name] &&
              this.messages[this.channels[b].subscription_name].length;

            if (!msg_count_a && !msg_count_b) return 0;
            if (msg_count_a && !msg_count_b) return -1;
            if (!msg_count_a && msg_count_b) return 1;

            let a_timetoken = this.messages[this.channels[a].subscription_name][
              this.messages[this.channels[a].subscription_name].length - 1
            ].timetoken;
            let b_timetoken = this.messages[this.channels[b].subscription_name][
              this.messages[this.channels[b].subscription_name].length - 1
            ].timetoken;

            if (a_timetoken > b_timetoken) return -1;
            if (a_timetoken < b_timetoken) return 1;
            return 0;
          })
      );

      if (this.mainWindowSearch == "") {
        return channels;
      } else {
        let wordExp = new RegExp(this.mainWindowSearch, "i");
        return channels.map(el =>
          el.filter(key => wordExp.test(this.channels[key].display_name))
        );
      }
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    var vue_el = this;
    window.addEventListener("beforeunload", function() {
      try {
        if (vue_el.setActiveUsersUUIDsArrayInterval_id) {
          clearInterval(vue_el.setActiveUsersUUIDsArrayInterval_id);
        }
        (<Pubnub>vue_el.pubnub).unsubscribeAll();
        (<Pubnub>vue_el.pubnub).stop();
        (<Pubnub>vue_el.pubnub).removeListener(vue_el.pubnubListener);
      } catch (error) {}
    });
    return;
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    try {
      if (this.setActiveUsersUUIDsArrayInterval_id) {
        clearInterval(this.setActiveUsersUUIDsArrayInterval_id);
      }
      (<ChatData>this).pubnub.unsubscribeAll();
      (<ChatData>this).pubnub.stop();
      (<ChatData>this).pubnub.removeListener(this.pubnubListener);
    } catch (error) {}
  },
  watch: {
    trans() {
      this.checkTranslationObjects();
    },
    async dynamicChannels() {
      this.pns.handleDynamicChannelOnUpdate();
    },
    isFullScreenProp() {
      this.handleFullScreenWatcher();
    },
    mainWindowExpandedProp: {
      handler: function() {
        //oldVal, newVal
        this.handleMainWindowExpandedProp();
      }
    },
    chatIsVisibleAndUnmutedProp: {
      handler: function() {
        this.chatIsVisibleAndUnmutedData = this.chatIsVisibleAndUnmutedProp;
      }
    },
    openChatByUuidProp: {
      handler: async function() {
        if (!this.openChatByUuidProp) return;
        // this.consoleLog(`activeUsersUUIDs`, this.activeUsersUUIDs);
        // this.consoleLog(`this.channels`, this.channels);
        // this.consoleLog(`this.openChatByUuidProp`, this.openChatByUuidProp);
        let hash = this.openChatByUuidProp.replace(/[^0-9a-zA-Z_]/g, char =>
          char.charCodeAt(0)
        );
        // hash = await this.digestMessage([this.uuid, hash].sort().join("_"))
        hash = await this.digestMessage(hash);
        // this.consoleLog(`hash`, hash);
        if (Object.keys(this.usersData).includes(hash)) {
          // this.consoleLog(`this.userdata[hash]`, this.usersData[hash]);
          if (this.mobileMode) {
            this.openChat(hash, false, true, false, true);
            this.fullScreen = true;
            this.respMainWindowOpen = false;
            this.emitCurrentMainWindow();
          } else {
            this.openChat(hash, true, true, false, true);
          }
          // this.consoleLog('### includes hash')
        }
      }
    },
    predefinedAliasName: {
      handler: function() {
        this.profileSettings.name = this.predefinedAliasName;
        this.pns.pubnub_saveProfileSettings();
      }
    },
    currentStatusProp: {
      handler: function() {
        // this.consoleLog(`this.currentStatusProp`, this.currentStatusProp);
        this.profileSettings.status = this.currentStatusProp;
        this.pns.pubnub_saveProfileSettings();
      }
    },
    profilePic: {
      handler: function() {
        this.profileSettings.profilePic = this.profilePic;
        this.pns.pubnub_saveProfileSettings();
      }
    }
  }
});
