


















































































































































































import {
  SvgComponent,
  CloseButton,
  MicrophoneButton,
  MicrophoneMuteButton,
  HangUpButton,
  NoVideoButton,
  VideoButton,
  CameraSwitchButton,
  FullScreenButton,
  SettingsButton
} from "./svgs.vue";
import Subscriber from "./Subscriber.vue";
import Publisher from "./Publisher.vue";
import OT from "@opentok/client";
export interface VideoChat {
  streams: [];
  session: OT.Session;
}
export default {
  components: {
    Subscriber,
    Publisher,
    SvgComponent,
    CloseButton,
    MicrophoneButton,
    MicrophoneMuteButton,
    HangUpButton,
    NoVideoButton,
    VideoButton,
    CameraSwitchButton,
    FullScreenButton,
    SettingsButton
  },
  props: {
    currentVideoChat: {
      required: true
    },
    profileSettings: {
      required: false
    },
    trans: {
      required: true
    },
    chatIsVisibleAndUnmutedData: {
      required: false
    }
  },
  data() {
    return {
      streams: [],
      session: null,
      microphoneOn: true,
      cameraOn: true,
      publisherOpts: {},
      subscriberOpts: {},
      video_expanded: false,
      //publisher messages
      accessDenied: false,
      pleaseGrantAccess: false,
      devices: [],
      currentVideoInput: null,
      currentAudioInput: null,
      deviceSettingsOpen: false,
      isMobileDevice: false
    } as VideoChat;
  },
  created() {
    this.publisherOpts["name"] = this.profileSettings.name;
    this.publisherOpts["style"] = { nameDisplayMode: "off" };
    // this.subscriberOpts['name'] = this.profileSettings.name;
    let nameDisplayMode =
      this.currentVideoChat.type == "private" ? "off" : "on";
    this.subscriberOpts["style"] = {
      nameDisplayMode,
      buttonDisplayMode: "on",
      audioBlockedDisplayMode: "on"
    };
  },
  async mounted() {
    try {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
          navigator.userAgent
        ) ||
        /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform)
      ) {
        this.isMobileDevice = true;
        this.$parent.consoleLog(`this.isMobileDevice`, this.isMobileDevice);
      }
      OT.getDevices((error, devices) => {
        this.devices = JSON.parse(JSON.stringify(devices));
        this.$parent.consoleLog(`error`, error);
      });
      this.session = OT.initSession(
        this.currentVideoChat.otApiKey,
        this.currentVideoChat.otSession
      );
      this.session.connect(this.currentVideoChat.otToken, err => {
        if (err) {
          this.errorHandler(err);
        }
        this.session.on("streamCreated", event => {
          this.streams.push(event.stream);
        });
        this.session.on("streamDestroyed", event => {
          const idx = this.streams.indexOf(event.stream);
          if (idx > -1) {
            this.streams.splice(idx, 1);
          }
        });
      });
    } catch (error) {
      try {
        if (this.session) {
          this.session.destroy();
        }
        this.$parent.logErrors(error);
      } catch (error) {
        this.$parent.logErrors(error);
      }
    }
    this.dragElement(document.querySelector("#otDragContainer"));
  },
  destroyed() {
    // (this.session as OT.Session)
    this.session.destroy();
  },
  computed: {
    videoDevices() {
      return this.devices.filter(el => el.kind == "videoInput");
    },
    audioDevices() {
      return this.devices.filter(el => el.kind == "audioInput");
    }
  },
  methods: {
    async cycleAudio() {
      // Setting an audio source to a new MediaStreamTrack
      const stream = await OT.getUserMedia({
        videoSource: null
      });

      const [audioSource] = stream.getAudioTracks();
      const publisher = this.$refs.publisher.getPublisher();

      publisher
        .setAudioSource(audioSource)
        .then(() => this.$parent.consoleLog("Audio source updated"));

      // Cycling through microphone inputs
      let audioInputs;
      let currentIndex = 0;
      OT.getDevices((err, devices) => {
        this.$parent.logErrors(err);
        audioInputs = devices.filter(device => device.kind === "audioInput");
        // Find the right starting index for cycleMicrophone
        audioInputs.forEach((device, idx) => {
          if (device.label === publisher.getAudioSource().label) {
            currentIndex = idx;
          }
        });
        currentIndex += 1;
        let deviceId = audioInputs[currentIndex % audioInputs.length].deviceId;
        publisher.setAudioSource(deviceId);
      });

      // const cycleMicrophone = () => {
      // };
    },
    changeAudioInput() {
      let device = this.devices.filter(
        el => el.label == this.currentAudioInput
      );
      if (device[0]) {
        this.$refs.publisher
          .getPublisher()
          .setAudioSource(device[0].deviceId)
          .then(() => {
            this.$parent.consoleLog(
              `this.$refs.publisher
          .getPublisher().getAudioSource() after`,
              this.$refs.publisher.getPublisher().getAudioSource()
            );
          })
          .catch(error => {
            this.$parent.consoleLog(`setAudioSource error`, error);
          });
      }

      this.$parent.consoleLog(`this.currentAudioInput`, this.currentAudioInput);
    },
    changeVideoSource() {
      // this.streams.forEach(stream => {
      //   stream.getTracks().forEach(track => {
      //     track.stop();
      //   });
      // });
      this.$refs.publisher
        .getPublisher()
        .setVideoSource(this.currentVideoInput)
        .then(() => {})
        .catch(error => {
          this.$parent.consoleLog(`setVideoSource error`, error);
        });
      this.$parent.consoleLog(`this.currentVideoInput`, this.currentVideoInput);
    },
    openDeviceSettings() {
      OT.getDevices((error, devices) => {
        this.devices = JSON.parse(JSON.stringify(devices));
        this.$parent.consoleLog(`error`, error);

        if (this.$refs.publisher) {
          this.$parent.consoleLog(
            `this.$refs.publisher.getPublisher()`,
            this.$refs.publisher.getPublisher()
          );
          this.currentVideoInput = this.$refs.publisher
            .getPublisher()
            .getVideoSource()?.deviceId;
          this.currentAudioInput = this.$refs.publisher
            .getPublisher()
            .getAudioSource()?.label;
          this.$parent.consoleLog(
            `this.$refs.publisher.getPublisher().getAudioSource()`,
            this.$refs.publisher.getPublisher().getAudioSource()
          );
          this.$parent.consoleLog(`this.devices`, this.devices);
          this.$parent.consoleLog(
            `this.currentVideoInput`,
            this.currentVideoInput
          );
          this.$parent.consoleLog(
            `this.currentAudioInput`,
            this.currentAudioInput
          );
        }
        this.deviceSettingsOpen = !this.deviceSettingsOpen;
      });
    },
    toggleAudio(on: boolean) {
      this.microphoneOn = on;
      // this.$refs.publisher.toggleAudio(on);
    },

    switchCam() {
      this.$refs.publisher
        .getPublisher()
        .cycleVideo()
        .then(() => {
          if (this.$refs.publisher) {
            this.$parent.consoleLog(
              `this.$refs.publisher.getPublisher()`,
              this.$refs.publisher.getPublisher()
            );
            this.currentVideoInput = this.$refs.publisher
              .getPublisher()
              .getVideoSource()?.deviceId;
            this.currentAudioInput = this.$refs.publisher
              .getPublisher()
              .getAudioSource()?.label;
            this.$parent.consoleLog(
              `this.$refs.publisher.getPublisher().getAudioSource()`,
              this.$refs.publisher.getPublisher().getAudioSource()
            );
            this.$parent.consoleLog(`this.devices`, this.devices);
            this.$parent.consoleLog(
              `this.currentVideoInput`,
              this.currentVideoInput
            );
            this.$parent.consoleLog(
              `this.currentAudioInput`,
              this.currentAudioInput
            );
          }
        })
        .catch(this.logErrors);
    },
    errorHandler(err) {
      this.$parent.logErrors(err);
      if (err.name == `OT_HARDWARE_UNAVAILABLE`) {
        //todo: something went wrong -> video or microphone unavailable ?
        alert(this.trans.errors.your_hardware_is_occupied_by_another_program);
      } else {
        alert(this.trans.errors.something_went_wrong_with_the_videochat);
      }

      this.$emit("close");
    },
    dragElement(elmnt) {
      let pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      if (document.getElementById(elmnt.id + "header")) {
        // if present, the header is where you move the DIV from:
        document.getElementById(
          elmnt.id + "header"
        ).onmousedown = dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:

        let yPos = elmnt.offsetTop - pos2;
        if (!(yPos < 0 || yPos > window.innerHeight - 300)) {
          elmnt.style.top = yPos + "px";
        } else {
          if (yPos < 0) {
            elmnt.style.top = 0 + "px";
          } else {
            elmnt.style.top = window.innerHeight - 300 + "px";
          }
        }
        let xPos = elmnt.offsetLeft - pos1;
        if (!(xPos < 0 || xPos > window.innerWidth - 300)) {
          elmnt.style.left = xPos + "px";
        } else {
          if (xPos < 0) {
            elmnt.style.left = 0 + "px";
          } else {
            elmnt.style.left = window.innerWidth - 300 + "px";
          }
        }
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
  }
};
