import { render, staticRenderFns } from "./vvenue-chat.vue?vue&type=template&id=47a60ff7&scoped=true&"
import script from "./vvenue-chat.vue?vue&type=script&lang=ts&"
export * from "./vvenue-chat.vue?vue&type=script&lang=ts&"
import style0 from "./vvenue-chat.vue?vue&type=style&index=0&id=47a60ff7&lang=scss&scoped=true&"
import style1 from "./vvenue-chat.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47a60ff7",
  null
  
)

export default component.exports